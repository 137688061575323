import type { FC } from 'react';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';

import type { TaskRow } from '../../../../dataSource';
import { DetailRow } from '../DetailRow';
import { useTaskDateLabel } from './hooks';
import useLoggedUserAccountRoles from '../../../../../../../hooks/useLoggedUserAccountRoles';
import { MissingDueDateInfoIcon } from '../../../../components/MissingDueDateInfoIcon';

type TaskDateInfoProps = Pick<TaskRow, 'status' | 'dueDate' | 'closedAt'>;

export const TaskDateDetailRow: FC<TaskDateInfoProps> = (props) => {
  const taskDateLabel = useTaskDateLabel(props);
  const { isUserAdmin } = useLoggedUserAccountRoles();

  if (!taskDateLabel) {
    return null;
  }

  const { testId, tooltip, label, color, isInfoTooltipDisplayed } = taskDateLabel;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <DetailRow
        label={label}
        icon={CalendarTodayOutlined}
        testId={testId}
        tooltip={tooltip}
        color={color}
        textVariant="bold"
      />
      {isInfoTooltipDisplayed && isUserAdmin ? <MissingDueDateInfoIcon /> : null}
    </Stack>
  );
};
