import { useAccountContext } from '../modules/account/AccountContext';
import { useAccountUsersById } from './useAccountUsersById';
import { useVerticeUsersById } from './useVerticeUsersById';
import { useMemo } from 'react';
import { AccountUser, UserInfo } from '@vertice/slices';
import { useLoggedUser } from '@verticeone/auth/src';

export type UsersByIdReturn = Record<string, AccountUser | UserInfo>;

export const useRelevantUsersById = (options?: { skip?: boolean; includeLoggedVerticeUser?: boolean }) => {
  const { accountId } = useAccountContext();
  const { usersById: accountUsersById, isLoadingUsers: isLoadingAccountUsers } = useAccountUsersById({
    skip: options?.skip || accountId === 'VERTICE_WORKFLOW_ADMINISTRATION',
  });
  const { usersById: verticeUsersById, isLoadingUsers: isLoadingVerticeUsers } = useVerticeUsersById({
    skip: options?.skip || accountId !== 'VERTICE_WORKFLOW_ADMINISTRATION',
  });

  const loggedUser = useLoggedUser();

  const usersById = useMemo(() => {
    const availableUsers: UsersByIdReturn = { ...accountUsersById, ...verticeUsersById };

    if (options?.includeLoggedVerticeUser && loggedUser.isIatUser) {
      const nameParts = loggedUser.name?.split(' ') ?? [];
      availableUsers[loggedUser.userId] = {
        userId: loggedUser.userId,
        email: loggedUser.email,
        firstName: nameParts[0],
        middleName: nameParts[1],
        lastName: nameParts[2],
      };
    }

    return availableUsers;
  }, [accountUsersById, loggedUser, options?.includeLoggedVerticeUser, verticeUsersById]);

  const isLoadingUsers = useMemo(
    () => isLoadingAccountUsers || isLoadingVerticeUsers,
    [isLoadingAccountUsers, isLoadingVerticeUsers]
  );

  return {
    usersById,
    isLoadingUsers,
  };
};
