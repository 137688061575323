import { Buffer } from 'buffer';

export type JiraRequest = {
  requestorEmail: string;
  requestorUserName: string;
  issueLink: string;
};

export type SlackRequest = {
  userName: string;
  linkToMessage?: string;
};

const JIRA_REQUEST_PREFIX = 'integrations/jira';
const SLACK_REQUEST_PREFIX = 'integrations/slack';

const containsPrefix = (prefix: string, ref?: string) => ref?.split(':')?.[4]?.startsWith(prefix);

export const decodeString = (str: string) => Buffer.from(window.decodeURIComponent(str), 'base64').toString('utf-8');

export const parseJiraRequest = (requestorRef?: string, sourceRef?: string): JiraRequest | undefined => {
  // requestorRef = urn:verticeone:vertice:{account_id}:integrations/jira/{user_id}/{user_email}/{user_name_base64}
  // sourceRef = "urn:verticeone:vertice:{account_id}:integrations/jira/issue/{issue_key}/{issue_link_base64}"

  const isJiraRequest =
    containsPrefix(JIRA_REQUEST_PREFIX, requestorRef) && containsPrefix(JIRA_REQUEST_PREFIX, sourceRef);
  const requestorRefParts = requestorRef?.split('/');
  const sourceRefParts = sourceRef?.split('/');

  if (!isJiraRequest || !requestorRefParts || !sourceRefParts) return;

  const requestorEmail = requestorRefParts[3];
  const requestorUserName = decodeString(requestorRefParts[4]);
  const issueLink = decodeString(sourceRefParts[4]);

  return {
    requestorEmail,
    requestorUserName,
    issueLink,
  };
};

export const parseSlackRequest = (requestorRef?: string, sourceRef?: string): SlackRequest | undefined => {
  // requestorRef = urn:verticeone:vertice:{account_id}:integrations/slack/{user_id}/{user_email}/{user_name_base64}
  // sourceRef = "urn:verticeone:vertice:{account_id}:integrations/slack/newRequestCommand/{channel_id}/{msg_ts::optional}/{msg_link_base64::optional}"

  const isSlackRequest =
    containsPrefix(SLACK_REQUEST_PREFIX, requestorRef) && containsPrefix(SLACK_REQUEST_PREFIX, sourceRef);
  const requestorRefParts = requestorRef?.split('/');
  const sourceRefParts = sourceRef?.split('/');

  if (!isSlackRequest || !requestorRefParts) return;

  const userName = requestorRefParts[4]
    ? `${decodeString(requestorRefParts[4])} (${requestorRefParts[3]})`
    : requestorRefParts[3];
  const msgLinkEncoded = sourceRefParts?.[5];
  const linkToMessage = msgLinkEncoded ? decodeString(msgLinkEncoded) : undefined;

  return {
    userName,
    linkToMessage,
  };
};
