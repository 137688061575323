import type { Feature } from '@vertice/slices/src/openapi/codegen/featuresAPI';

// Extend the Window interface
declare global {
  interface Window {
    vertice: {
      overrideFeature: (feature: string, override: Feature) => void;
      clearFeatureOverrides: () => void;
    };
    zESettings: any;
    zE: any;
  }
}

export const OVERRIDE_KEY = 'feature_overrides';
const WHITELIST_FEATURES = [
  'AWS_ANALYTICS',
  'AWS_SPRIO',
  'AWS_SPRIO_COMPUTE_SP',
  'AWS_SPRIO_EC2_SP',
  'AWS_SPRIO_SAGEMAKER_SP',
  'AWS_SPRIO_EC2_RI',
  'AWS_SPRIO_RDS_RI',
  'AWS_SPRIO_OPENSEARCH_RI',
  'AWS_SPRIO_ELASTICACHE_RI',
  'AWS_SPRIO_REDSHIFT_RI',
  'DASHBOARD',
];

window.vertice = window.vertice || {};

// Override a feature
window.vertice.overrideFeature = (feature, override) => {
  try {
    if (!WHITELIST_FEATURES.includes(feature)) {
      console.warn(`Feature ${feature} is not whitelisted for overrides`);
      return;
    }
    // Get the current overrides from local storage
    let overrides = JSON.parse(localStorage.getItem(OVERRIDE_KEY) || '{}');

    // Merge the new override
    overrides[feature] = {
      ...overrides[feature],
      ...override,
    };

    // Store the updated overrides back into local storage
    localStorage.setItem(OVERRIDE_KEY, JSON.stringify(overrides));

    // Manually trigger the storage event handler
    window.dispatchEvent(new StorageEvent(OVERRIDE_KEY));
  } catch (error) {
    console.error(`Error overriding feature ${feature}:`, error);
  }
};

// Clear all feature overrides
window.vertice.clearFeatureOverrides = () => {
  localStorage.removeItem(OVERRIDE_KEY);

  // Manually trigger the storage event handler
  window.dispatchEvent(new StorageEvent(OVERRIDE_KEY));
};
